/* The contents of this file are literal copy paste from 'booking-ingredients/src/lib/BrowserDetector.js' */

export const MOBILE_OS = {
  ANDROID: 'android',
  IOS: 'ios',
  WINDOWS_PHONE: 'windows-phone',
  UNKNOWN: 'unknown'
}

export function getMobileOperatingSystem(userAgentStr) {
  const userAgent =
    userAgentStr || navigator.userAgent || navigator.vendor || window.opera

  if (/windows phone/i.test(userAgent)) return MOBILE_OS.WINDOWS_PHONE

  if (/android/i.test(userAgent)) return MOBILE_OS.ANDROID

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    return MOBILE_OS.IOS

  return MOBILE_OS.UNKNOWN
}
