// RELEASE: MIKI-1.8.0; TICKET: MIKI-2570
// remove unused values from the object as they are merged
// with default in sso anyway
const settings = ({ ssoUrl, onSignIn, onSignOut, language }) => ({
  container: '.sso-component-container',
  isMobile: true,
  mobileBackground: false,
  language,
  pathToServer: ssoUrl,
  accountSource: undefined,
  linkToSettings: '',
  links: [],
  showSignedInMobile: false,
  ga: function() {},
  onRender: function() {},
  onSignIn,
  onSignOut,
  onExpandOverlay: function() {},
  onCollapseOverlay: function() {},
  // Useless, only reports errors for /username-login requests and such.
  // (no state during automatic validation, and sso user request)
  onError: function() {}
})

export function signOut() {
  return window.SSO && window.SSO.signOut()
}

export function open() {
  return window.SSO && window.SSO.open()
}

export function close() {
  return window.SSO && window.SSO.close()
}

export function render(params) {
  if (window.SSO) render()
  else window.SSOLoadingInterval = window.setInterval(render, 100)

  function render() {
    if (!window.SSO) return
    if (window.SSOLoadingInterval)
      window.clearInterval(window.SSOLoadingInterval)

    window.SSO.render(settings(params))
  }
}
