const ENV_TOP = 'env(safe-area-inset-top)'
const CONSTANT_TOP = 'constant(safe-area-inset-top)'

export default function getViewportSafeAreaInsetTop() {
  if (CSS.supports(`padding-top: ${ENV_TOP}`)) {
    return getCalculatedPropertyValue(ENV_TOP)
  } else if (CSS.supports(`padding-top: ${CONSTANT_TOP}`)) {
    return getCalculatedPropertyValue(CONSTANT_TOP)
  }
  return 0
}

function getCalculatedPropertyValue(value) {
  document.documentElement.style.setProperty('--safe-area-inset-top', value)
  return parseInt(
    window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--safe-area-inset-top')
      .replace(/\D/g, ''),
    10
  )
}
