import * as smoothScroll from '../util/smoothScroll'
import { getMobileOperatingSystem } from '../util/mobileOperatingSystem'

/**
 *
 * @param {{parent: Element, callBack: callBack, child: Element, isForward: boolean}} parameters
 * @param {number} [duration]
 */
export function smoothHorizontalScrollTo(parameters, duration = 500) {
  const { parent, child, callBack } = parameters

  if (typeof parameters.isForward === 'undefined') parameters.isForward = true

  const start = parent.scrollLeft
  const end = parameters.isForward
    ? start + child.getBoundingClientRect().width
    : start - child.getBoundingClientRect().width
  const clock = Date.now()

  const step = () =>
    requestAnimationFrame(() => {
      const elapsed = Date.now() - clock
      parent.scrollLeft = smoothScroll.position(start, end, elapsed, duration)

      if (elapsed > duration) {
        if (typeof callBack === 'function') callBack()
      } else {
        step()
      }
    })

  if (duration === 0 || duration < 0) {
    parent.scrollTo(end, 0)
  } else {
    step()
  }
}

/**
 *
 * @param {{parent: Element, callBack: callBack, child: Element, isForward: boolean}} parameters
 */
export function smoothHorizontalScrollToElement(parameters) {
  if (
    typeof window !== 'undefined' &&
    !!parameters.parent &&
    !!parameters.child
  )
    smoothHorizontalScrollTo(parameters, 300)
}

class Gallery extends HTMLElement {
  constructor() {
    super()

    this.nextButtonSelector = 'next'
    this.previousButtonSelector = 'previous'
    this.nextButton = null
    this.previousButton = null
    this.slider = null
    this.timer = null
  }

  /**
   *
   * @returns {boolean}
   * @private
   */
  _isLastSlide() {
    const imagesCount = this.querySelectorAll('.image').length
    const lastImage = this.querySelector(`[data-id="${imagesCount - 1}"]`)
    const roundedSliderScrollWidthWithoutLastElement = Math.floor(
      this.slider.scrollWidth - lastImage.getBoundingClientRect().width
    )

    return roundedSliderScrollWidthWithoutLastElement <= this.slider.scrollLeft
  }

  /**
   *
   * @returns {boolean}
   * @private
   */
  _isFirstSlide() {
    const firstImage = this.querySelector(`[data-id="0"]`)
    return (
      Math.floor(firstImage.getBoundingClientRect().width) >
      this.slider.scrollLeft
    )
  }

  /**
   *
   * @param {[string]} classNames
   * @private
   */
  _createButton(classNames) {
    const isValid = Array.isArray(classNames)
      ? classNames.every((item) => typeof item === 'string')
      : false

    if (!isValid) return

    const button = this.appendChild(document.createElement('button'))
    button.classList.add(...classNames, 'controlButton')
    button.innerHTML =
      '<img src="../assets/images/_cacheBooking/arrow.svg" alt ="">'

    return button
  }

  _disableSnapScroll() {
    this.slider.classList.add('disableScrollSnap')
  }

  _enableScrollSnap() {
    this.slider.classList.remove('disableScrollSnap')
  }

  /**
   *
   * @param {Element} button
   * @param {boolean} [isForward]
   * @returns {{parent: Element, callBack: callBack, child: Element, isForward: boolean}}
   * @private
   */
  _runSmoothHorizontalScroll(button, isForward) {
    if (document.body.contains(button)) {
      this._disableSnapScroll()

      smoothHorizontalScrollToElement({
        parent: this.slider,
        child: this.querySelector('.image'),
        callBack: () => this._enableScrollSnap(),
        isForward: typeof isForward === 'boolean' ? isForward : true,
      })
    }
  }

  connectedCallback() {
    this.nextButton = this._createButton([this.nextButtonSelector])
    this.previousButton = this._createButton([
      'hidden',
      this.previousButtonSelector,
    ])
    this.slider = this.querySelector('.images')
    this.slider.classList.add(getMobileOperatingSystem())

    this.nextButton.addEventListener('click', () => {
      this._runSmoothHorizontalScroll(this.nextButton, true)
    })

    this.previousButton.addEventListener('click', () => {
      this._runSmoothHorizontalScroll(this.previousButton, false)
    })

    this.slider.addEventListener('scroll', () => {
      if (this.timer !== null) {
        clearTimeout(this.timer)
      }

      if (this._isFirstSlide()) {
        this.previousButton.classList.add('hidden')
        this.nextButton.classList.remove('hidden')
      } else if (this._isLastSlide()) {
        this.previousButton.classList.remove('hidden')
        this.nextButton.classList.add('hidden')
      } else {
        this.previousButton.classList.remove('hidden')
        this.nextButton.classList.remove('hidden')
      }

      if (!this.nextButton.disabled && !this.previousButton.disabled) {
        this.nextButton.disabled = true
        this.previousButton.disabled = true
      }

      this.timer = setTimeout(() => {
        this.nextButton.disabled = false
        this.previousButton.disabled = false
      }, 200)
    })
  }
}

customElements.define('image-gallery', Gallery)
