class ExpandableExpander extends HTMLElement {
  constructor() {
    super()
    this.MAX_HEIGHT = 50
  }

  connectedCallback() {
    this.content = this.querySelector('.content')
    const elementHeight = this.content.getClientRects()[0].height

    if (elementHeight > this.MAX_HEIGHT) {
      this.shouldWatchElement = true
      this.content.classList.add('tooLong')
      this.content.style.height = this.MAX_HEIGHT / 10 + 'rem'
    }
  }

  static get observedAttributes() {
    return ['data-is-expanded']
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this.shouldWatchElement) return

    if (document.body.contains(this.content)) {
      if (newValue === 'true') {
        this._expandElement(this.content)
      }

      if (newValue === 'false') {
        this._collapseElement(this.content)
      }
    }
  }

  _expandElement(element) {
    element.classList.add('open')

    element.style.height = element.scrollHeight + 'px'

    const onTransitionEnd = () =>
      element.removeEventListener('transitionend', onTransitionEnd)

    element.addEventListener('transitionend', onTransitionEnd)
  }

  _collapseElement(element) {
    element.classList.remove('open')

    const elementTransition = this.style.transition
    element.style.transition = ''

    requestAnimationFrame(() => {
      element.style.height = element.scrollHeight + 'px'
      element.style.transition = elementTransition

      requestAnimationFrame(() => {
        element.style.height = this.MAX_HEIGHT / 10 + 'rem'
      })
    })
  }
}

customElements.define('expandable-expander', ExpandableExpander)
