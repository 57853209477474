export default function createForm(submitFormData) {
  const form = document.createElement('form')
  form.action = submitFormData.action
  form.method = submitFormData.method
  form.style.display = 'none'

  Object.entries(submitFormData.properties).forEach(([key, value]) => {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', key)
    input.setAttribute('value', value)

    form.appendChild(input)
  })

  return form
}
