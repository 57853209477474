export default elmProgram => {
  elmProgram.ports.renderKlarnaSnippet.subscribe(klarnaResponse =>
    // In elm the view function is queued with requestAnimationFrame before
    // commands, using requestAnimationFrame ensures that this function is run
    // after the view has rendered.
    // https://discourse.elm-lang.org/t/when-is-cmd-actually-processed/1008/2
    window.requestAnimationFrame(() => renderKlarnaSnippet(klarnaResponse))
  )
}

function renderKlarnaSnippet(klarnaResponse) {
  const klarnaSnippetContainer = document.querySelector('.klarnaSnippet')

  if (!klarnaSnippetContainer) return window.history.back()
  klarnaSnippetContainer.innerHTML = klarnaResponse.snippet
  replaceScriptNode(klarnaSnippetContainer)
}

// Helper functions for inserting scripts from api into html.
// If snippet is rendered with plain `innerHTML`, scripts do not work.
// https://stackoverflow.com/questions/1197575/can-scripts-be-inserted-with-innerhtml#1197585

function replaceScriptNode(node) {
  if (node.tagName === 'SCRIPT') {
    node.parentNode.replaceChild(cloneScriptNode(node), node)
  } else {
    node.childNodes.forEach(replaceScriptNode)
  }

  return node
}

function cloneScriptNode(node) {
  const script = document.createElement('script')

  script.text = node.innerHTML
  Array.from(node.attributes).forEach(({ name, value }) =>
    script.setAttribute(name, value)
  )

  return script
}
