class ExpandableDescription extends HTMLElement {
  constructor() {
    super()
  }

  connectedCallback() {
    this.longDescription = this.querySelector('.expandableDescription')
  }

  static get observedAttributes() {
    return ['data-is-expanded']
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (document.body.contains(this.longDescription)) {
      if (newValue === 'true') {
        this._expandElement(this.longDescription)
      }

      if (newValue === 'false') {
        this._collapseElement(this.longDescription)
      }
    }
  }

  _collapseElement(element) {
    const elementTransition = this.style.transition
    element.style.transition = ''

    requestAnimationFrame(() => {
      element.style.height = element.scrollHeight + 'px'
      element.style.transition = elementTransition

      requestAnimationFrame(() => {
        element.style.height = 0
      })
    })
  }

  _expandElement(element) {
    element.style.height = element.scrollHeight + 'px'

    const onTransitionEnd = () =>
      element.removeEventListener('transitionend', onTransitionEnd)

    element.addEventListener('transitionend', onTransitionEnd)
  }
}

customElements.define('expandable-description', ExpandableDescription)
