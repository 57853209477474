import URI from 'urijs'
import * as SSO from './singleSignOn'

let isSignInWithUrlParams = false

// Native app

function toMikiClubOneClient(client) {
  return {
    ...client,
    birthday: client.birthday,
    firstName: client.firstName.toLowerCase(),
    lastName: client.lastName.toLowerCase()
  }
}

export function getNativeUserData() {
  // exported for tests
  if (window.native && window.native.getUserLogin) {
    const maybeNativeUserData = JSON.parse(window.native.getUserLogin())
    if (maybeNativeUserData && maybeNativeUserData.userLogin) {
      return {
        user: toMikiClubOneClient(maybeNativeUserData.userLogin),
        ...(maybeNativeUserData.ssoToken
          ? { ssoToken: maybeNativeUserData.ssoToken }
          : {})
      }
    } else {
      return null
    }
  } else {
    return null
  }
}

export function extractCredentialsFromUrlParams(locationSearch) {
  // exported for tests
  const clubOneCredentials = {
    firstName: locationSearch.clubOneAccountFirstName,
    lastName: locationSearch.clubOneAccountLastName,
    clubone: locationSearch.clubOneAccountNumber
  }
  const areClubOneCredentialsPresent = Object.values(clubOneCredentials).reduce(
    (prev, next) => prev && next,
    true
  )

  if (areClubOneCredentialsPresent) return clubOneCredentials
  return null
}

function initWithUrlCredentials(urlParams) {
  const clubOneCredentialsFromUrl = extractCredentialsFromUrlParams(urlParams)
  if (clubOneCredentialsFromUrl) {
    isSignInWithUrlParams = true
    return { clubOneCredentials: clubOneCredentialsFromUrl }
  }
  return {}
}

function initNative() {
  try {
    const nativeUserData = getNativeUserData()

    return {
      ssoToken: nativeUserData && nativeUserData.ssoToken,
      clubOneClient: nativeUserData && nativeUserData.user
    }
  } catch (error) {
    return {}
  }
}

export function getNativeAppFlags({ urlParams }) {
  if (window.native && window.native.getUserLogin) {
    return initNative()
  } else {
    return initWithUrlCredentials(urlParams)
  }
}

// SSO

export function subscriptions(elmProgram) {
  elmProgram.ports.signOut.subscribe(
    () => !isSignInWithUrlParams && SSO.signOut()
  )
  elmProgram.ports.goToSignInPage.subscribe(SSO.open)
}

export function loadSSOScript({ elmProgram, url, language }) {
  const urlParams = new URI(location.href).search(true)

  if (urlParams.protractor) return
  const { onSignOut, gotSingleSignOnToken } = elmProgram.ports

  const script = document.createElement('script')
  script.src = `${url}/component/${
    url.includes('dev') ? 'sso.js' : 'sso.min.js'
  }`
  document.body.appendChild(script)

  script.onload = () => {
    if (!!urlParams.backendError === false) {
      SSO.render({
        ssoUrl: url,
        onSignIn: token => gotSingleSignOnToken.send({ token }),
        onSignOut: () => onSignOut.send(true),
        language
      })
    }
  }
}
