import { getItem, setItem } from './localStorage.util'

export function updateVouchers(resId, voucher) {
  if (voucher) {
    const vouchers = (getItem('vouchers') || []).filter(
      (v) => v.resId !== resId
    )

    setItem('vouchers', [...vouchers, { resId, voucher }])
  }
}
