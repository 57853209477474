function easeInOutCubic(t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
}

export function position(start, end, elapsed, duration) {
  if (elapsed > duration) return end
  return start + (end - start) * easeInOutCubic(elapsed / duration)
}

export const defaultConfig = {
  duration: 300,
  easing: easeInOutCubic,
  context: window,
  callback: () => {},
}

function setViewportY({ config = defaultConfig, y = 0 }) {
  if (config.context === window) {
    window.scrollTo(0, y)
  } else {
    context.scrollTop = y
  }
}

function step({ config, start, end, startTime }) {
  const elapsed = Date.now() - startTime

  if (elapsed > config.duration) {
    setViewportY({ config, y: end })
    requestAnimationFrame(config.callback)
  } else {
    setViewportY({ config, y: position(start, end, elapsed, config.duration) })
    requestAnimationFrame(() => step({ config, start, end, startTime }))
  }
}

export function scrollTo({ config = defaultConfig, y = 0 }) {
  const { context } = config
  const start = context === window ? window.pageYOffset : context.scrollTop

  step({ config, start, end: y, startTime: Date.now() })
}

export function scrollToTop() {
  if (typeof window !== 'undefined') {
    scrollTo({ config: { ...defaultConfig, duration: 300 }, y: 0 })
  }
}
