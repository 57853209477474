export const STORED_BANK =
  'STORED_BANK'

export default function getMemorizedRussianBank() {
  try {
    if (!window.localStorage || !window.localStorage.getItem) return null

    return JSON.parse(
      window.localStorage.getItem(STORED_BANK)
    )
  } catch (e) {
    return null
  }
}
