class StickyHeader extends HTMLElement {
  constructor() {
    super()

    this.STICKY_SCROLL_TO_TOP_HEIGHT = 32
    this.DIRECTION_SWITCHER_HEIGHT = 96
    this.isHeaderSticky = null
    this.headerText = ''
    this.cachedPaddingTop = null
    this.fixedDirectionSwitcher = null
    this.updateHeaderStickinessAndHeaderTextListener = null
  }

  connectedCallback() {
    this.fixedDirectionSwitcher = this.querySelector('.directionSwitcher')
    this.updateHeaderStickinessAndHeaderTextListener = () => {
      this._updateHeaderStickiness()

      if (this._getHeaderText() !== this.headerText) {
        this.headerText = this._getHeaderText()
        this.dispatchEvent(
          new CustomEvent('setDynamicDate', { detail: this.headerText })
        )
        return this.headerText
      }
    }

    document.addEventListener(
      'scroll',
      this.updateHeaderStickinessAndHeaderTextListener
    )
  }

  disconnectedCallback() {
    document.removeEventListener(
      'scroll',
      this.updateHeaderStickinessAndHeaderTextListener
    )
  }

  /**
   *
   * @returns {null|number}
   * @private
   */
  _getPaddingTop() {
    if (this.cachedPaddingTop) return this.cachedPaddingTop
    const timetableHeaderTopPart = document.querySelector(
      '.timetableHeaderTopPart'
    )
    if (!timetableHeaderTopPart) return 0
    this.cachedPaddingTop = parseFloat(
      window
        .getComputedStyle(timetableHeaderTopPart, null)
        .getPropertyValue('padding-top')
    )
    return this.cachedPaddingTop
  }

  /**
   *
   * @returns {number}
   * @private
   */
  _getStickinessStartPosition() {
    const timetableHeaderTopPart = document.querySelector(
      '.timetableHeaderTopPart'
    )
    if (!timetableHeaderTopPart) return 0

    return (
      timetableHeaderTopPart.getBoundingClientRect().height -
      this._getPaddingTop() -
      this.STICKY_SCROLL_TO_TOP_HEIGHT
    )
  }

  /**
   *
   * @returns {null}
   * @private
   */
  _updateHeaderStickiness() {
    const newIsHeaderSticky =
      window.pageYOffset > this._getStickinessStartPosition()

    if (newIsHeaderSticky !== this.isHeaderSticky) {
      this.isHeaderSticky = newIsHeaderSticky
      this.dispatchEvent(
        new CustomEvent('showScrollToTop', { detail: this.isHeaderSticky })
      )

      return this.isHeaderSticky
    }
  }

  /**
   *
   * @returns {string|string}
   * @private
   */
  _getHeaderText() {
    const sailLists = document.querySelectorAll('.slide.active .table')
    if (!sailLists) return ''

    for (let i = 0; i < sailLists.length; i += 1) {
      if (
        sailLists[i].getBoundingClientRect().bottom >
        this._getFixedDirectionSwitcherBottomPosition()
      ) {
        const maybeHeaderElement = sailLists[i].querySelector('.dayHeader')
        return (maybeHeaderElement && maybeHeaderElement.textContent) || ''
      }
    }

    return ''
  }

  /**
   *
   * @returns {number}
   * @private
   */
  _getFixedDirectionSwitcherBottomPosition() {
    if (this.fixedDirectionSwitcher) {
      return this.fixedDirectionSwitcher.getBoundingClientRect().bottom
    } else {
      return (
        this._getPaddingTop() +
        this.STICKY_SCROLL_TO_TOP_HEIGHT +
        this.DIRECTION_SWITCHER_HEIGHT
      )
    }
  }
}

customElements.define('sticky-header', StickyHeader)
