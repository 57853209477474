import './Gallery'

export default function subscriptions(elmProgram) {
  const {
    showTravelClassDescription,
    onShowTravelClassDescription,
    hideTravelClassDescription,
    onHideTravelClassDescription
  } = elmProgram.ports

  showTravelClassDescription.subscribe(code => {
    const element = getTravelClassDescription(code)
    if (element) {
      expandElement(element, () => onShowTravelClassDescription.send(code))
    }
  })

  hideTravelClassDescription.subscribe(code => {
    const element = getTravelClassDescription(code)
    if (element) {
      collapseElement(element, () => onHideTravelClassDescription.send(code))
    }
  })
}

function collapseElement(element, callback) {
  var elementTransition = element.style.transition
  element.style.transition = ''

  requestAnimationFrame(() => {
    element.style.height = element.scrollHeight + 'px'
    element.style.transition = elementTransition

    requestAnimationFrame(() => {
      element.style.height = 0
    })
  })

  callback()
}

function expandElement(element, callback) {
  element.style.height = element.scrollHeight + 'px'

  const onTransitionEnd = () =>
    element.removeEventListener('transitionend', onTransitionEnd)

  element.addEventListener('transitionend', onTransitionEnd)

  callback()
}

function getTravelClassDescription(code) {
  return document.querySelector(
    `[data-test-travel-class-code="${code}"] .longDescription`
  )
}
