/* eslint-disable no-inner-declarations */
import '@babel/polyfill' // findIndex, Promise and so forth
import './index.scss'

import URI from 'urijs'
import { getMobileOperatingSystem } from './util/mobileOperatingSystem'
import getMemorizedFirstSelectedVehicle, { VEHICLE_STORAGE_KEY } from './util/memorizedFirstSelectedVehicle'
import createForm from './util/formAutoSubmit'
import { updateVouchers } from './util/voucher.util'
import connectKlarnaPaymentPagePorts from './KlarnaPaymentPage/klarnaPaymentPagePorts'
import getViewportSafeAreaInsetTop from './util/safeArea.util'
import getMemorizedRussianBank, { STORED_BANK } from './util/memorizedRussianBank'
import * as UserService from './user.service'
import './TimetablePage/StickyHeader'
import './Common/ExpandableDescription'
import './Common/ExpandableExpander'
import travelClassPageSubscriptions from './TravelClassPage/travelClassPage.service'
import { Elm } from './Main.elm'
import * as MathUtil from './util/math'
import * as tracking from './util/tracking'
import * as SmoothScroll from './util/smoothScroll'
import { onInitCookieConsent } from './util/cookieConsent'

let currentUrl = location.href

function init() {
  const uri = new URI(currentUrl)
  const urlParams = uri.search(true)

  const isNative = !!(window.native || urlParams.native)

  showOrHideAppBackButton(uri.path())

  const flags = {
    userAgent: navigator.userAgent || navigator.vendor || window.opera,
    isNative: isNative,
    locationHref: window.location.href,
    mobileOperatingSystem: getMobileOperatingSystem(),
    memorizedFirstSelectedVehicle: getMemorizedFirstSelectedVehicle(),
    memorizedBankId: getMemorizedRussianBank(),
    viewportSafeAreaInsetTop: getViewportSafeAreaInsetTop(),
    ...(isNative ? UserService.getNativeAppFlags({ urlParams }) : {}),
    devicePixelRatio: MathUtil.clamp(1, 4, window.devicePixelRatio || 1)
  }

  const elmProgram = Elm.Main.init({
    flags,
  })

  subscriptions(elmProgram)
  animatePriceBar()
}

function animatePriceBar() {
  window.addEventListener('scroll', () => {
    const DISTANCE_TO_BOTTOM_THRESHOLD = 150
    const distanceToBottom = document.body.scrollHeight - window.scrollY - window.innerHeight
    const scrollingToBottomProgress = Math.max(0, ((DISTANCE_TO_BOTTOM_THRESHOLD - distanceToBottom) / DISTANCE_TO_BOTTOM_THRESHOLD))

    const floatButton = document.querySelector('button.floatButton.book')
    const bottomBar = document.querySelector('#fixed-bottom-bar')
    const buttonPlaceholder = document.querySelector('.buttonPlaceholder')

    if (floatButton && bottomBar && buttonPlaceholder) {
      const safeAreaInsetBottom = getComputedStyle(document.documentElement).getPropertyValue('--sab')
      floatButton.style.transform = `translateY(calc((${safeAreaInsetBottom} + 0.8rem) * ${-scrollingToBottomProgress}))`
      bottomBar.style.transform = `translateY(calc((${safeAreaInsetBottom} + 3.4rem) * ${scrollingToBottomProgress}))`

      const toastsTotalHeight = [].reduce.call(document.querySelectorAll('div[toast]'), (acc, el) => acc + el.offsetHeight, 0)
      buttonPlaceholder.style.paddingBottom = toastsTotalHeight + 'px'
    }
  })
}

function subscriptions(elmProgram) {
  elmProgram.ports.init.subscribe(({ serverConfig, language, ssoLanguage }) => {
    document.getElementsByTagName('html')[0].setAttribute('lang', language)
    UserService.loadSSOScript({
      elmProgram,
      url: serverConfig.sso,
      language: ssoLanguage,
    })
  })

  elmProgram.ports.initCookieConsent.subscribe(onInitCookieConsent)

  elmProgram.ports.initRecaptcha.subscribe(({ clientKey, language }) => {
    addRecaptchaHandlers()
    addRecaptchaScript()

    function addRecaptchaHandlers() {
      window.recaptchaOnSubmitHandler = (data) => {
        elmProgram.ports.onGotRecaptcha.send(data)
      }

      window.recaptchaOnErrorHandler = (error) => {
        // eslint-disable-next-line no-console
        console.error('Recaptcha error occurred', error)
      }

      window.recaptchaOnLoadHandler = () => {
        window.grecaptcha.render('recaptcha', {
          sitekey: clientKey,
          hl: language,
          size: 'invisible',
          callback: 'recaptchaOnSubmitHandler',
          'error-callback': 'recaptchaOnErrorHandler'
        })
      }
    }

    function addRecaptchaScript() {
      const script = document.createElement('script')
      script.async = true
      script.defer = true
      script.type = 'text/javascript'
      script.src =
        'https://www.google.com/recaptcha/api.js?onload=recaptchaOnLoadHandler'
      const s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(script, s)
    }
  })

  elmProgram.ports.sendAppLoadedMessageToNativeWrapper.subscribe(() => {
    /* Hack to get correct safe area inset top in iOS webview. When trying to get
    the value too early, this returns 0 for some magical reasons.
    */
    elmProgram.ports.calculatedSafeAreaInsetTop.send(
      getViewportSafeAreaInsetTop()
    )
    try {
      window.native.sendMessage('onload', window.location.pathname)
    } catch (err) {
      return null
    }
  })

  elmProgram.ports.goBack.subscribe(() => {
    if (window.native && window.native.sendMessage) {
      window.native.sendMessage('goBack')
    }
  })

  elmProgram.ports.hideBackButton.subscribe(() => {
    try {
      window.native.sendMessage('hideBackButton')
    } catch (err) {
      return null
    }
  })

  elmProgram.ports.showBackButton.subscribe(() => {
    try {
      window.native.sendMessage('showBackButton')
    } catch (err) {
      return null
    }
  })

  elmProgram.ports.submitFormData.subscribe((a) =>
    submitFormData(elmProgram, a)
  )

  elmProgram.ports.executeRecaptcha.subscribe(() => {
    window.grecaptcha.execute()
  })

  elmProgram.ports.saveFirstVehicleToLocalStorage.subscribe(
    saveVehicleToStorage
  )
  elmProgram.ports.removeFirstVehicleFromLocalStorage.subscribe(
    removeVehicleFromStorage
  )

  elmProgram.ports.saveSelectedRussianBankId.subscribe(
    saveRussianBankIdInStorage
  )

  connectKlarnaPaymentPagePorts(elmProgram)

  elmProgram.ports.digiData.subscribe(tracking.setDigiData)
  elmProgram.ports.logError.subscribe(tracking.logError)
  window.onerror = tracking.onWindowError

  UserService.subscriptions(elmProgram)

  travelClassPageSubscriptions(elmProgram)

  elmProgram.ports.scrollTo.subscribe(scrollElementIntoView)
  elmProgram.ports.scrollToSelectedSail.subscribe(scrollToSelectedSail)
  elmProgram.ports.scrollIntoView.subscribe(scrollIntoView)
  elmProgram.ports.scrollToTop.subscribe(SmoothScroll.scrollToTop)
}

function scrollIntoView({ selector, alignToTop }) {
  requestAnimationFrame(() => {
    const element = document.querySelector(selector)
    if (!element) return

    element.scrollIntoView(alignToTop)
  })
}

function scrollToSelectedSail() {
  SmoothScroll.scrollTo({ config: { ...SmoothScroll.defaultConfig, duration: 0 }, y: 0 })

  setTimeout(() => {
    const voyageTypeSelectorElement = document.querySelector('.voyageTypeSelectorContainer')
    const scrollToTopHeight = voyageTypeSelectorElement ? 32 : 0
    const stickyHeaderElement = document.querySelector('sticky-header')
    const stickyHeaderHeight = stickyHeaderElement ? 96 + scrollToTopHeight : 0

    const selectedSailElement = document.querySelector(`.active .sail.active`)
    const offsetTop = getViewportSafeAreaInsetTop()
    const offsetBottom = getFixedBottomBarHeight()

    if (!isElementInView(selectedSailElement, offsetTop, offsetBottom)) {
      const dateTableElement = selectedSailElement.closest('.table')
      const dateTableTop = dateTableElement.getBoundingClientRect().top
      const selectedSailTop = selectedSailElement.getBoundingClientRect().top

      if (isSelectedSailVisibleAfterScrollToDateHeader()) scrollToDateHeader()
      else scrollToSelectedSail()

      function isSelectedSailVisibleAfterScrollToDateHeader() {
        return selectedSailTop - dateTableTop < window.innerHeight - stickyHeaderHeight - offsetBottom - offsetTop
      }

      function scrollToDateHeader() {
        const dateHeaderElement = dateTableElement.querySelector('.dayHeader')
        const dateHeaderHeight = dateHeaderElement ? dateHeaderElement.clientHeight : 0
        const y = stickyHeaderElement
          ? dateTableTop + window.scrollY - offsetTop - stickyHeaderHeight + dateHeaderHeight
          : dateTableTop + window.scrollY - offsetTop
        SmoothScroll.scrollTo({ config: { ...SmoothScroll.defaultConfig, duration: 500 }, y })
      }

      function scrollToSelectedSail() {
        const y = selectedSailTop + window.scrollY - offsetTop - stickyHeaderHeight
        SmoothScroll.scrollTo({ config: { ...SmoothScroll.defaultConfig, duration: 500 }, y })
      }
    }
  }, 200)
}

function scrollElementIntoView({ id }) {
  requestAnimationFrame(() => {
    const element = document.getElementById(id)
    if (!element) return

    const offsetTop = getViewportSafeAreaInsetTop()
    const offsetBottom = getFixedBottomBarHeight()
    const y = element.getBoundingClientRect().top + window.scrollY - offsetTop

    if (!isElementInView(element, offsetTop, offsetBottom)) {
      SmoothScroll.scrollTo({ config: SmoothScroll.defaultConfig, y })
    }
  })
}

function getFixedBottomBarHeight() {
  const fixedBottomBar = document.getElementById('fixed-bottom-bar')
  return (fixedBottomBar && fixedBottomBar.offsetHeight) || 0
}

function isElementInView(el, offsetTop = 0, offsetBottom = 0) {
  const viewport = {}
  viewport.top = window.pageYOffset + offsetTop
  viewport.bottom = window.pageYOffset + window.innerHeight - offsetBottom

  const bounds = {}
  bounds.top = el.getBoundingClientRect().top + window.pageYOffset
  bounds.bottom = bounds.top + el.clientHeight

  return bounds.bottom <= viewport.bottom && bounds.top >= viewport.top
}

function saveVehicleToStorage(vehicle) {
  if (!window.localStorage || !window.localStorage.setItem) return

  window.localStorage.setItem(VEHICLE_STORAGE_KEY, JSON.stringify(vehicle))
}

function removeVehicleFromStorage() {
  if (!window.localStorage || !window.localStorage.removeItem) return

  window.localStorage.removeItem(VEHICLE_STORAGE_KEY)
}

function showOrHideAppBackButton(location) {
  try {
    if (window.native && window.native.sendMessage && location === '/') {
      window.native.sendMessage('showBackButton')
    } else {
      window.native.sendMessage('hideBackButton')
    }
  } catch (err) {
    return null
  }
}

function saveRussianBankIdInStorage(id) {
  if (!window.localStorage || !window.localStorage.setItem) return

  window.localStorage.setItem(STORED_BANK, JSON.stringify(id))
}

function submitFormData(elmProgram, { response, voucherId, method }) {
  const { url, parameters, resId, rejectUrl } = response

  if (voucherId) updateVouchers(resId, voucherId)

  const appUrl = new URI(location.href)
    .addSearch({ redirectUrl: rejectUrl })
    .toString()

  history.replaceState({}, '', appUrl)

  const form = createForm({
    action: url,
    properties: parameters,
    method
  })
  document.querySelector('.page').appendChild(form)
  form.submit()
}

init()
